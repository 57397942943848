.infoHolder {
  width: 70%;
  margin: 0 auto;

  /* margin-top: 2em; */
}

.infoHolder h1 {
  font-weight: 600;
}

.infoaccordionbg {
  position: relative;
  background: linear-gradient(90deg, #4bd3c5 2.97%, #4bd3c5 100%);
  border-radius: 15px;
  padding-top: 15px;
  width: 100%;
  min-height: 48vh;
}
input[type="text"],
input[type="tel"],
input[type="email"],
input[type="date"] {
  font-family: "Poppins", sans-serif !important;
}
.wallet {
  display: flex;
  flex-direction: row;
}
.appleimg {
  margin-right: 10px;
}

.infoaccSummary {
  background: #fff;
  border-radius: 15px;
  box-shadow: 3px 4px 13px lightgrey;
  min-height: 48vh;
  margin-bottom: 20px;
  padding-bottom: 50px;
}
label {
  font-family: "Poppins", sans-serif !important;
}

.welcometext {
  /* margin: 040px; */
  margin-top: 2em !important;
}

/* .MuiCollapse-wrapper {
  box-shadow: 0px 4px 9px rgb(0 0 0 / 25%);
  border-radius: 15px;
} */
.gotcss {
  font-size: 20px;
}
.gotquestions {
  display: flex;
  margin-bottom: 20px;
}

.Infogrid {
  padding-top: 12px;
  padding-left: 18px;
  display: flex;
  justify-content: space-between;
}

.loaderAdj {
  display: flex;
  justify-content: center;
  align-items: center;
}

.submit .btn {
  background: #4bd3c5;
  border-radius: 16px;
  border-radius: 15px;
  /* padding: 0.8em 6em; */
  color: #fff;
  border: none;
  font-size: 16px;
  cursor: pointer;
  width: 15%;
  /* margin-right: 36px; */
  /* height: 47px; */
}
.submit .btn1 {
  background: #4bd3c5;
  border-radius: 16px;
  border-radius: 15px;
  /* padding: 0.8em 6em; */
  color: #fff;
  border: none;
  font-size: 16px;
  cursor: pointer;
  width: 15%;
}
.submit {
  display: flex;
  justify-content: center;
}

.adjs {
  font-size: 12px;
}

@media only screen and (max-width: 600px) {
  .infoHolder {
    width: 95%;

    margin: 0 10px 0 10px;
  }
  

  .submit .btn {
    width: 30%;
    margin-right: 48px;
  }
  .submit .btn1 {
    width: 30%;
    margin-right: 0 !important;
  }
  .submit .btn3 {
    width: 70% !important;
  }
  
  .paperModal {
    position: absolute;
    width: 86%;
    background-color: #fff;
    border-radius: 20px;
  }
  .center img {
    height: 191px;
  }
  .closeHolder img {
    position: absolute;
    top: 9px;
    right: 6px;
    z-index: 11;
    height: 23px;
  }

  .successmsg h1 {
    font-weight: 400;
    font-size: 20px;
  }
  .content {
    background: linear-gradient(90deg, #6fe2e9 2.97%, #00afb9 100%);
    padding: 1em 0;
    border-radius: 20px;
    -webkit-clip-path: polygon(0% 0%, 88% 0%, 100% 28%, 100% 100%, 0% 100%);
    clip-path: polygon(0% 0%, 83% 0%, 100% 28%, 100% 100%, 0% 100%);
  }
  option {
    font-family: "Poppins", sans-serif !important;
  }
  .rander img {
    position: absolute;
    height: 100px;
    height: 99px;
    left: 50%;
    transform: translate(-160%, 50%);
  }

  .adjs {
    font-size: 12px;
  }

  .accordionHolder {
    width: 100%;
  }

}
.popImage {
  max-height: 120px;
}
.popImage1 {
  max-height: 180px;
}
.MuiCheckbox-root {
  color: #00afb9 !important;
}
.MuiInput-underline:after {
  border-bottom: 2px solid #00afb9 !important;
}
.MuiFormLabel-root.Mui-focused {
  color: #00afb9 !important;
}
@media only screen and (min-width: 601px) and (max-width: 768px) {
  .accordionHolder {
    width: 100%;
  }
}

@media only screen and (min-width: 769px) and (max-width: 1024px) {
  .adjs {
    font-size: 12px;
  }

  .adjsd {
    font-size: 12px;
  }
}

.accordionbgform {
  position: relative;
  background: linear-gradient(90deg, #4bd3c5 2.97%, #4bd3c5 100%);
  border-radius: 15px;
  padding-top: 10px;
  width: 85%;
  min-height: 48vh;
  margin-top: 30px;
}
.MuiFormControlLabel-root {
  margin-right: 14px !important;
}
/* .LoginMethod .MuiFormControlLabel-root {
  margin-right: 100px !important;
} */

.Infogrid {
  display: flex;
  justify-content: space-between;
}
.nextbtn {
  background: #4bd3c5;
  border: none;
  padding: 10px;
  width: 90%;
  border-radius: 10px;
  color: white;
  font-size: 18px;
}
.accSummaryform {
  background: #fff;
  border-radius: 15px;
  box-shadow: 3px 4px 13px lightgrey;
  min-height: 60vh;
}
@media only screen and (max-width: 960px) {
  .formTop1 {
    margin-top: 15px !important;
  }

  
  .submit .btn {
    width: 30%;
    margin-right: 36px;
  }
}
@media only screen and (max-width: 600px) {
  .accordionbgform {
    width: 100% !important;
  }

  .stepper div,
  .stepper a {
    font-size: 14px !important;
  }
}

.formTop1 {
  margin-top: 25px !important;
}
.formTop {
  margin-top: 10px !important;
}
.formTop3 {
  margin: 10px 0 5px 0 !important;
}
.loaderAdj {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 70vh;
}

.errorText {
  font-family: "Poppins", sans-serif;
  font-size: 12px;
  color: rgb(191, 38, 0);
  margin-top: 10px;
  margin-bottom: -10px;
}
.SuccessText {
  font-family: "Poppins", sans-serif;
  font-size: 15px;
  color: #55a055;
  margin-top: 10px;
  margin-bottom: -10px;
  font-weight: 700;
}
.MuiInputBase-root::placeholder {
  color: black !important;
}
.ThankInfo {
  text-align: center;
  padding-top: 13vh;
  font-size: 20px;
}
.Thankyou {
  padding-bottom: 35px;
}

input[type="file"] {
  display: none;
}

.custom-file-upload1 {
  position: relative;
  padding: 12px 93px !important;
  border-radius: 5px;
  -moz-border-radius: 5px;
  border: 1px dashed #ccc;
  text-align: center;
  background: #f07167;
  color: white;
  cursor: pointer;
}
.uploaddiv {
  margin-bottom: 20px;
}

@media only screen and (max-width: 400px) {
  .submit .btn {
    width: 30%;
    margin: 0 auto;
  }
}
.removearr
{
  float: right;
    margin-top: 25px;
    margin-right: 33px;
}
.removearr a{
  color: #f07167;
    cursor: pointer;
}